import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Lodash from "lodash";
import '@/filters' // 注入全局过滤器
import "./assets/iconfont/iconfont.css";
import './assets/style/reset.scss'
import './assets/style/element-variables.scss'
import './assets/style/public.scss'
import {ajax, clearLoginInfo, setTheme} from '@/utils'
//form回填js
// import './utils/autofillform'
import '@/permission';

import {Message, MessageBox, Loading, CheckboxGroup,Checkbox} from "element-ui";
import axios from "axios";

//导入配置好的国际化语言包
import i18n from './i18n'
import {clearLoginAndReload} from "@/utils";
import {getIpPermission, getPersonalitySetting, getTenantInfo, viewImage} from "common/interface";
import {setTenantInfo} from "@/store/actions";

// evenBus定义
// Vue.prototype.$eventBus = new Vue()
Vue.config.productionTip = false
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype._ = Lodash;

Vue.use(Loading);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);

axios.interceptors.request.use((config)=>{//拦截器
  if(store.state.token){
    config.headers.token = store.state.token;
  }

  return config
})

axios.interceptors.response.use((response)=>{
  let code = response.data.code;
  if(code === 401){
    clearLoginInfo();
    router.push('/')
  }else if(code === 403) {
    router.push('/403')
  }
  return response
},error => {
  if(error.response.status === 401) {
    clearLoginInfo();
    router.push('/')
  }else if(error.response.status === 403) {
    Message.error(error.response?.data?.cnMessage)
    router.push('/403')
  }
})

//获取系统配置
document.title = '学位论文管理系统';
setSystemSetting()

async function setSystemSetting(){
  // const settingRes = await getPersonalitySetting();
  // if(!settingRes){
  //   renderVue();
  //   return
  // }
  //系统配置和ip配置结合获取
  const results = await Promise.all([getPersonalitySetting(), getIpPermission()]);
  const personalitySetting = results[0];
  const ipPermission = results[1] || {};
  if(!personalitySetting){
    renderVue();
    return
  }
  let settingData = personalitySetting?.data;
  const tenants = settingData?.tenants || [];
  let currentTenant = {};
  // if(tenants?.length===1){//为什么要刚好等于1？？
  if(tenants?.length>0){
    currentTenant=tenants[0];
  }
  settingData={...settingData,permissionObj:ipPermission?.data,currentTenant}
  // if(settingData?.tenants?.length===1){//说明就一个分校，后期不需要选择分校
  //   const tenantcode= settingData.tenants[0].tenantcode;
  //   settingData={...settingData,tenantcode}
  // }
  if(settingData?.themeColor){//如果有主题色
    await setTheme(settingData.themeColor)
  }
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'icon';
  if(settingData?.ico){//动态创建一个icon
    const blobRes = await viewImage({filepath:settingData?.ico});
    let blob = new Blob([blobRes.data]);　　　 // 创建Blob对象，设置文件类型
    let objectUrl = URL.createObjectURL(blob);　　　　// 创建URL
    link.href = objectUrl;
  }else{
    link.href = '/favicon.ico';
  }
  document.getElementsByTagName('head')[0].appendChild(link);
  if(settingData?.hasScrollBar==='1'){
    const body = document.getElementsByTagName('body')[0];
    body.style.overflowx='hidden'
    body.style.overflowY='scroll'
  }
  store.commit('SET_SYSTEM_INFO', settingData);
  // console.log(settingData)
  if(settingData?.tenants?.length){//默认选第一个分校，以后多个就要弹出选择
    const tenantcode= settingData.tenants[0].tenantcode;
    let tenantRes = await getTenantInfo(tenantcode)
    let tenantInfo = tenantRes.data;
    localStorage.setItem('tenantInfo', JSON.stringify(tenantInfo));
    store.commit('SET_TENANTINFO', tenantInfo);
  }
  renderVue();
}

function renderVue(){
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

/**
 * 替换主题色/带透明度的背景颜色
 */
// setCssVar('--color-primary', '#1d701a')      // value 是颜色值，比如：#409EFF

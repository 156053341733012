import * as types from "./mutation-types";
import {SET_CURRENT_PAPER_INFO, SET_LANGUAGE} from "./mutation-types";
import {getIpPermission, getPaperList, getPersonalitySetting, getTenantInfo} from "@/common/interface";
import {systemInfo} from "@/store/getters";
import {casloginFn} from "common/interface/account";
import store from "@/store/index";
export const setUserInfo = function ({ commit }, userInfo) {
  const {logintype} = store.state.userInfo//cas登录的话里面有logintype，别的地方获取不到的只有登录后获取到，但是后期用户信息回更新，这个状态必须保存写回用户
  let userNow={...userInfo}
  if(logintype){
    userNow={...userNow,logintype}
  }
	// commit(types.SET_USERINFO, userInfo);
	commit(types.SET_USERINFO, userNow);
};
export const setToken = function ({ commit }, token) {
	commit(types.SET_TOKEN, token);
};
export const setTenantInfo = function ({ commit }, tenantInfo) {
	commit(types.SET_TENANTINFO, tenantInfo);
};
export const setAdvanceSearchGroup = function ({ commit }, advanceSearchGroup) {
	commit(types.SET_ADVANCESEARCHGROUP, advanceSearchGroup);
};
export const setAgreeState = function ({ commit }, agreeState) {
	commit(types.SET_AGREESTATE, agreeState);
};
export const setLanguage = function ({ commit }, language) {
	commit(types.SET_LANGUAGE, language);
};
export const setSystemInfo = async function ({ commit,state }, systemInfo) {
  // commit(types.SET_SYSTEM_INFO, systemInfo);
  const results = await Promise.all([getPersonalitySetting(), getIpPermission()]);
  const personalitySetting = results[0];
  const ipPermission = results[1] || {};
  let settingData = personalitySetting?.data;
  let permission = ipPermission?.data;
  settingData={...settingData,permissionObj:permission|| {}}
  // console.log(state.systemInfo)
  // console.log({...state?.systemInfo})
  // console.log({...state?.systemInfo,...settingData})
  // console.log('---------------------------')
  commit(types.SET_SYSTEM_INFO, {...state?.systemInfo,...settingData});
  return Promise.resolve(); // 返回一个 Promise 表示数据已更新
};
// export const setCurrentPaperInfo = function ({ commit }, currentPaperInfo) {
// 	commit(types.SET_CURRENT_PAPER_INFO, currentPaperInfo);
// };
// export const set= async function ({ commit , state }, payload) {
// 	console.log(payload)
// 	if(state.rows === null) {
// 		const rows = await getPaperList(payload)
// 		commit('设置rows', rows)
// 	}
// 	return state.rows
// }
export const setCurrentPaperInfo= async function ({ commit , state }, payload) {
	// if(state.currentPaperInfo === null) {//null为初始化没有发请求的时候，
		const {learnid,tenantcode}= payload
		const rows = await getPaperList({learnid,tenantcode})
		const defaultItem=rows?.[0] || {}
		commit(types.SET_CURRENT_PAPER_INFO, defaultItem)
  return Promise.resolve(); // 返回一个 Promise 表示数据已更新
	// }
	// return state.currentPaperInfo
}

export const setCasLoginUserInfo= async function ({ commit , state }, payload) {
  // if(state.currentPaperInfo === null) {//null为初始化没有发请求的时候，
  const {learnid,name,currentTenant,vcode}= payload
  const res = await casloginFn({learnid,name,tenantcode:currentTenant?.tenantcode,vcode})
  if(res.code===200){
    const casInfo = res.data || {};
    console.log(casInfo)
    const {token,accountinfo,...otherInfo} = casInfo
    const accountinfoObj = accountinfo || {};
    const userInfo = {...otherInfo,...accountinfoObj};
    console.log(userInfo)
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    store.commit('SET_USERINFO', userInfo);
    localStorage.setItem('token', token);
    store.commit('SET_TOKEN', token);
    let tenantRes = await getTenantInfo(userInfo.tenantcode)
    let tenantInfo = tenantRes.data;
    localStorage.setItem('tenantInfo', JSON.stringify(tenantInfo));
    store.commit('SET_TENANTINFO', tenantInfo);
  }
  return Promise.resolve(res)
}


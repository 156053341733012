
const publicStore = {
  namespaced: true,
  state: () => ({
    isMobile: false,//是否是移动端
  }),
  getters: {
    isMobile(state){
      return state.isMobile
    }
  },
  mutations: {
    setDeviceStatus(state, deviceStatus){
      state.isMobile = deviceStatus
    }
  },
  actions: {
    async updateDeviceStatus({commit},deviceStatus){
      commit("setDeviceStatus", deviceStatus);
    },
  }
}
export default publicStore

import {ajax} from "@/utils";
import {ip} from "assets/const";

export function getIpPermission () {
  return ajax({
    method: 'post',
    url: ip + 'ipPermission'
  }).then((res) => {
    return Promise.resolve(res)
  }).catch(err => {
    return Promise.resolve(false)
  })
}

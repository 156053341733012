// 处理过期的院系/专业列表的下拉显示(通过id匹配)
import {isFalsy} from "@/utils/cleanObject";

export const dealDepartmentSpecialLimitListById = (departments, currentDepartmentId) => {
  const list = departments.filter(item=>(!isFalsy(currentDepartmentId) && item.islimit==='yes' && item.id === currentDepartmentId) || item.islimit==='no') || []
  // console.log(list)
  return list
}

// 处理过期的院系/专业列表的下拉显示(通过name匹配)
export const dealDepartmentSpecialLimitListByName = (departments,name) => {
  const list = departments.filter(item=>(!isFalsy(name) && item.islimit==='yes' && (item.cname === name || item.ename === name)) || item.islimit==='no') || []
  // console.log(departments.filter(item=>(!isFalsy(name) && item.islimit==='yes' && item.cname === name || item.ename === name)) || [])
  // console.log(departments)
  // console.log(name)
  // console.log(list)
  return list
}

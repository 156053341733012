import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import {clearLoginInfo, getLocationItem, publixLoginGuid, removeLocationItem, setLocationItem} from "@/utils";
import {MessageBox} from "element-ui";
import * as types from "@/store/mutation-types";

NProgress.configure({showSpinner: false}) // NProgress Configuration
// const freeRouter = ['/','/login']
let blackList = ['/commit']
let isDetailAdded = false;
router.beforeEach(async (to, from, next) => {
  console.log('beforeEach to:' + to.path)
  // start progress bar
  NProgress.start();

  if (!store.state.language) {
    let language = localStorage.getItem('language');
    if (!(language && !['null', 'undefined'].includes(language))) {
      language = 'zh_CN'
    }
    localStorage.setItem('language', language);
    store.commit('SET_LANGUAGE', language);
  }

  // if(freeRouter.indexOf(to.path)>-1) {//如果是login页面不需要用户信息就直接可以访问
  //   next();
  //   NProgress.done();
  //   return;
  // }


  if (!store.state.tenantInfo) {
    let tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
    if (tenantInfo) {
      store.commit('SET_TENANTINFO', tenantInfo);
    }
  }

  // if(!store.state.agreeState) {//不要存缓存，每次刷新都弹出
  //   let agreeState = JSON.parse(localStorage.getItem('agreeState'));
  //   store.commit('SET_AGREESTATE',agreeState || false)
  // }

  //如果统一认证回来的
  if (to.query.learnid && to.query.vcode) {
    const {learnid, vcode, name} = to.query;
    // 复制当前的查询参数
    let query = { ...to.query };
    // 定义需要移除的查询参数
    const paramsToRemove = ['learnid', 'vcode', 'name'];
    // 遍历并移除指定的查询参数
    paramsToRemove.forEach(param => {
      if (query[param]) {
        delete query[param];
      }
    });

    const casRes = await store.dispatch('setCasLoginUserInfo', {
      ...store.state.systemInfo, vcode, learnid, name
    })
    if (casRes?.code === 200) {
      const casInfo = casRes?.data
      if (casInfo?.accountinfo?.rolecode === 'user') {
        if (!store.state.currentPaperInfo) {
          await store.dispatch('setCurrentPaperInfo', {
            ...store.state.userInfo
          })
        }
        const currentPaperInfo = store.state.currentPaperInfo;
        // const loginUrl = getLocationItem('loginUrl')
        // removeLocationItem('loginUrl')
        // if (loginUrl && !['commit'].includes(loginUrl)) {//如果缓存有地址且不是论文提交页，就跳转到对应地址，论文提交页单独处理，走之前逻辑
        //   window.location.href = loginUrl
        //   return
        // }
        if (to.path.indexOf('/commit') > -1) {//如果去提交页面
          if ((!currentPaperInfo?.lwstatus || currentPaperInfo?.lwstatus === 'notsubmit')) {//如果没提交过论文,到个人信息页
            next('/commit/info/myInfo')
          } else {
            next('/commit/info/paperReview')
          }
          return
        }
        //这边用next(to.path)不用next()是为了不带上统一认证过来的参数，如果需要参数只能用next，并且把需要的参数带上，不要的去掉
        // console.log(query)
        next({
          path:to.path,
          query
        })
      } else if (casInfo?.accountinfo?.rolecode === 'visitor') {
        console.log('visitor: '+to.path)
        if (to.path.indexOf('/commit') > -1) {//去提交页面，没有信息，就去完善信息页面
          console.log('用户首次登录，去完善信息')
          next('/information')
          return
        }
        console.log('去指定页面')
        //这边用next(to.path)不用next()是为了不带上统一认证过来的参数，如果需要参数只能用next，并且把需要的参数带上，不要的去掉
        next({
          path:to.path,
          query
        })
      }
    }
  }


  if (!store.state.userInfo) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
    let token = localStorage.getItem('token');
    if (userInfo) {//如果有用户信息
      store.commit('SET_USERINFO', userInfo);
      store.commit('SET_TOKEN', token);
      if (userInfo.managertype === 'tenant') {//如果是单位机构
        store.commit('SET_TENANTINFO', tenantInfo);
      }
    }
  }

  const systemInfo = store.state.systemInfo
  if (to.path === '/detail') {//详情页拦截
    if (!systemInfo?.permissionObj?.allowMetadata) {//不满足ip范围内
      await new Promise((resolve, reject) => {
        MessageBox.alert(this.$t('ip不在范围内哦'), this.$t('提示'), {
          type: 'warning',
          confirmButtonText: '我知道了',
          callback: action => {
            // 用户关闭提示框后执行 resolve
            resolve();
          }
        });
      });
      // 这边判断下如果是新的打开的页面直接输入详情地址，跳转到首页，如果是已经存在的页面输入详情，那停留在当前页
      // console.log(to)
      // console.log(from)
      next(from.path);
      return
    }
    // console.log(systemInfo?.metadataLoginFilter)
    if (systemInfo?.metadataLoginFilter === '1') {//查看元数据需要登录
      if (!store.state.userInfo) {//如果未登录，需要登录
        await new Promise((resolve, reject) => {
          MessageBox.alert(this.$t('请先登录~'), this.$t('提示'), {
            type: 'warning',
            confirmButtonText: '我知道了',
            callback: action => {
              // 用户关闭提示框后执行 resolve
              resolve();
            }
          });
        });
        //黑名单加上这个，后面会引导引导去登录页
        blackList.push('/detail');
      }
    }
  }

  // if (to.path === '/detail' && !isDetailAdded && systemInfo?.metadataLoginFilter==='1') {//查看元数据需要登录
  //   blackList.push('/detail');
  //   isDetailAdded = true;？？忘记干嘛用了
  // }

  const isBlacklisted = blackList.some(item => to.path.startsWith(item));
  if (store.state.userInfo) {//登录后
    if (to.path === '/login') {
      next('/')
    } else {
      //这边是登录后的
      if (to.path.indexOf('/commit') > -1) {//如果当前是去提交页面
        if (!store.state.currentPaperInfo) {
          await store.dispatch('setCurrentPaperInfo', {
            ...store.state.userInfo
          })
        }
        const currentPaperInfo = store.state.currentPaperInfo;
        if(store.state.userInfo?.rolecode === 'visitor'){//游客，引导去完善信息页面
          next('/information')
          return
        }
        //回执单权限控制
        if (['ReturnReceipt'].includes(to.name)) {
          if (!['waitinclude', 'included'].includes(currentPaperInfo?.lwstatus)) {
            next('/wrong')
            return
          }
        }
      }
      next();
    }
  } else {
    if (!isBlacklisted) {//白名单包括login，放行
      // console.log(`白名单${to.path}`)
      if (to.path === '/login') {//登录之前要存个缓存地址
        if (!to?.query?.redirect) {//代表是白名单，不是重定向过来的
          setLocationItem('loginUrl', window.location.href)//存入要跳转的地址，登录后直接跳转
        }
        //拦截去登录
        if (['2'].includes(systemInfo?.loginType)) {//如果只配置了统一认证
          window.location.href = `${systemInfo?.casloginUrl}?url=${encodeURIComponent(window.location.href)}`;
          return
        }
      }
      next()
    } else {//如果直接访问(直接输入url访问)的地址属于黑名单，去登录
      // console.log(`黑名单${to.path}`)
      if (to.path.startsWith('/commit')) {
        setLocationItem('loginUrl', 'commit')//存入要跳转的地址，登录后直接跳转
      } else {
        setLocationItem('loginUrl', window.location.href)//存入要跳转的地址，登录后直接跳转
      }
      //拦截去登录
      if (['2'].includes(systemInfo?.loginType)) {//如果只配置了统一认证
        let ecodeUrl = window.location.origin
        if(to.path.startsWith('/commit')){//去提交页,因为没有登录的时候，这边点提交url没有变
            ecodeUrl = `${window.location.origin}/commit/info/paperReview`
        }
        console.log(ecodeUrl)
        console.log(`${systemInfo?.casloginUrl}?url=${encodeURIComponent(ecodeUrl)}`)
        window.location.href = `${systemInfo?.casloginUrl}?url=${encodeURIComponent(ecodeUrl)}`;
      } else {
        next(`/login?redirect=${encodeURIComponent(to.path)}`)
      }
    }

  }

})


router.afterEach(() => {
  NProgress.done();
})

router.beforeResolve(function (to, form, next) {
  document.title = store.state.systemInfo?.systemName || '学位论文管理系统';
  next();
});

export const account = 'account/';//用户管理
export const sortlist = 'sortlist/';//分类列表
export const papersearch = 'papersearch/';//论文检索管理
export const template = 'template/';//模板管理
export const notation = 'notation/';//首页及审核批注配置管理
export const docobject = 'docobject/';//论文文件管理
export const departments = 'departments/';//院系专业管理
export const stutype = 'stutype/';//学生类型
export const defvalue = 'defvalue/';//缺省值管理
export const secret = 'secret/';//保密级别管理
export const paper = 'paper/';//论文管理
export const newinfo = 'newinfo/';//通知公告FAQ管理
export const tenant = 'tenant/';//单位机构管理
export const reqrepor = 'reqrepor/';//博士后报告申请管理
export const trainingunit = 'trainingunit/';//培养单位管理
export const etdfile = 'etdfile/';//文件管理
export const setting = 'setting/';//配置管理
export const middle = 'middle/';//中间库
export const ip = 'ip/';//ip控制管理

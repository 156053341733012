//排除value为0的情况
export const isFalsy = (value) => value===0 ? false : !value
//清除没有值的项
export const cleanObject = (object)=>{
    const result = {...object}
    Object.keys(result).forEach(key => {
        const value = result[key]
        if(typeof value === 'string'){//提到放这边先去掉收尾空格，防止输入空格的时候也回写头部
            result[key] = value.trim();
        }
        if(isFalsy(value)){
            delete result[key]
            return
        }
        // if(key==='identifier'){//这边先判断isbn是否规范，如果规范,允许-拼接的isbn做替换isbn校验
        //     //这边不转成string类型的话，isbn纯数字组成会报错
        //     result[key] = result[key].toString().replace(/-/g,"");
        // }
    })
    return result
}

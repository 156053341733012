<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  computed:{
    ...mapGetters(['systemInfo'])
  },
  mounted() {
    this.resizeFn()
    window.addEventListener('resize', this.resizeFn)
  },
  methods:{
    resizeFn(){
      const clientWidth = window.innerWidth
      this.$store.dispatch('publicModule/updateDeviceStatus', clientWidth <= 850)
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  }
  // created() {
  //   if(this.$route.path==='/home'){
  //     if(this.systemInfo?.hasScrollBar==='1'){
  //       const body = document.getElementsByTagName('body')[0];
  //       body.style.overflowx='hidden'
  //       body.style.overflowY='scroll'
  //     }
  //   }
  // }
}
</script>
<style lang="scss">
</style>
